<template>
    <div>
        新建问题
    </div>
</template> 

<script>
import { mapGetters } from "vuex"; 

export default {
    name: "EditContract",
    components: {

    },
    props: ['modelId'],
    computed: {...mapGetters([''])},
    data() {
        return {
            form: {
                modelName: ''
            }
        }
    },
    async mounted() {			
       this.init()            
    },

    methods: {
        init() {
            if (this.modelId > 0){

            }
        },
        onClose() {
            this.$router.push({ name: 'model' })
        }
    }
}
</script>

<style scoped>


</style>